import React, { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import { Header } from '@components/header';
import { Footer } from '@components/footer';
import GlobalStyle from '@styles/globalStyles';

type Props = {
  children: ReactNode;
};

export default function Layout({ children }: Props) {
  const { i18n } = useTranslation('common');

  return (
    <>
      <GlobalStyle lang={i18n.language} />
      <Header />
      {children}
      <Footer />
    </>
  );
}
