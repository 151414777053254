import React from 'react';
import NextHead from 'next/head';
import { useTranslation } from 'next-i18next';
interface propType {
  isMobileView?: boolean;
  title?: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogUrl?: string;
  ogImageOriginal?: boolean;
  ogImageUrl?: string;
  ogTwitterTitle?: string;
  ogTwitterCardSize?: string;
}
// 공용적으로 사용되는 head 태그 들에 대하여 정의되어 있는 컴포넌트입니다.
export const Head: React.FC<propType> = ({
  isMobileView = false,
  title = '',
  description = '',
  ogImageOriginal = false,
  ogImageUrl = '',
  ogUrl = '',
  ogTitle = '',
  ogTwitterTitle = '',
  ogDescription = '',
  ogTwitterCardSize = '',
}) => {
  // 번역 도구
  const { i18n } = useTranslation('common');

  const headTitle = title
    ? title
    : (function () {
      if (i18n.language === 'ko') return '에이콘3D - 3D모델과 함께 창작이 재밌고 쉬워지는 곳';
      else if (i18n.language === 'zh') return '3D模型背景平台 - ACON3D';

      return '3D Models for All Creators - ACON3D';
    })();
  const keywords = (function () {
    if (i18n.language === 'ko') return '웹툰배경, 웹툰, 3d 디자인, 3d 모델, 3d, vr, ar, 애니메이션, 스케치업, sketchup, 3dmax, 건축, 게임, webtoon, webcomics';
    else if (i18n.language === 'zh') return 'webtoon背景，3d設計，3d模型，動畫，設計，遊戲，建築, 建模, webtoon background, 3d design, 3d model, 3d, vr, ar, animation, design, sketchup, 3dmax, game, architechture webtoon, webcomics, modeling';
    else if (i18n.language === 'ja') return 'アニメ, アニメーション, 漫画, ar, vr, 設計, 建築, 3d, 3d 設計, sketchup, 3dmax, game, ゲーム,モデリング, 3d モデル, モデル, 背景, 漫画背景';

    return 'webtoon background, 3d design, 3d model, 3d, vr, ar, animation, design, sketchup, 3dmax, game, architechture webtoon, webcomics, modeling';
  })();
  const headDescription = description
    ? description
    : (function () {
      if (i18n.language === 'ko') return '3D 디자인 오픈 플랫폼. 웹툰, 게임, 애니메이션, VR 등 창작에 필요한 3D모델을 자유롭게 사용하세요.';
      else if (i18n.language === 'zh') return '韩国最大的3D模型漫画背景以及素材平台！ 在ACON3D扩展你的创作世界吧！';
      else if (i18n.language === 'ja') return '3Dデザインオープンプラットフォーム。 ウェブトゥーン、ゲーム、アニメ、VRなど創作に必要な3Dモデルをご自由にお使いください。';

      return '3D Design open platform. 3D model for webtoons, games, animation, VR, and other creative fields!';
    })();

  const headOgTitle = ogTitle ? ogTitle : headTitle;
  const headOgDescription = ogDescription ? ogDescription : headDescription;
  const headOgUrl = ogUrl ? ogUrl : 'www.acon3d.com';
  const headOgImageUrl = ogImageOriginal
    ? ogImageUrl
    : ogImageUrl
      ? `https://www.acon3d.com/cdn-cgi/image/w=1200,q=95/${ogImageUrl}`
      : 'https://www.acon3d.com/data/common/snsRepresentImage.png';
  const headOgTwitterCardSize = ogTwitterCardSize ? ogTwitterCardSize : 'summary';

  return (
    <>
      <NextHead>
        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-M9WJW4J" />

        <title>{headTitle}</title>

        {/* 인코딩 및 import file */}
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <link rel="icon" href="/icons/favicon.png" type="image/x-icon" />
        {isMobileView ? <meta name="viewport" content="width=device-width, initial-scale=1" /> : <meta name="viewport" content="width=900" />}
        <meta name="author" content="ACON3D" />

        {/* 다국어 처리 */}
        <link className="default" rel="canonical" href="https://www.acon3d.com" />
        <link className="ko" rel="alternate" hrefLang="ko-KR" href="https://www.acon3d.com/ko" />
        <link className="en" rel="alternate" hrefLang="en-US" href="https://www.acon3d.com/en" />
        <link className="jp" rel="alternate" hrefLang="ja-JP" href="https://www.acon3d.com/ja" />
        <link className="cn" rel="alternate" hrefLang="zh-CN" href="https://www.acon3d.com/zh" />

        <meta property="og:site_name" content="ACON3D" />

        {/* open graph */}
        <meta property="title" content={headTitle} />
        <meta property="description" content={headDescription} />
        <meta property="keywords" content={keywords} />
        <meta property="og:title" content={headOgTitle} />
        <meta property="og:description" content={headOgDescription} />
        <meta property="og:url" content={headOgUrl} />
        <meta property="og:image" content={headOgImageUrl} />

        {/* twitter 용 */}
        <meta name="twitter:title" content={ogTwitterTitle ? ogTwitterTitle : headOgTitle} />
        <meta name="twitter:description" content={headOgDescription} />
        <meta name="twitter:url" content={headOgUrl} />
        <meta name="twitter:image" content={headOgImageUrl} />
        <meta name="twitter:card" content={headOgTwitterCardSize} />
      </NextHead>
    </>
  );
};
