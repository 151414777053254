import React, { MouseEventHandler } from 'react';
import Link from 'next/link';
import { isMobile } from 'react-device-detect';
import Image from 'next/image';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { GET_SEARCH_KEYWORD_LIST } from '@api';
import { convertLangCodeForDB } from '@lib';
import { RightArrowIcon } from 'src/assets/icons';
import { FlexRowAlignCenter } from '@components/wrapper';
import { CustomText } from '@components/ui';
import { Group } from './group';
import { RemoveAllItemButton, SearchFooter, SearchFooterLink, SearchList } from './styled';

interface props {
  recentItems: [string?];
  onClickRemoveAllItemButton: MouseEventHandler;
  onBlurInput: () => void;
}

export const List: React.FC<props> = ({ recentItems, onBlurInput, onClickRemoveAllItemButton }) => {
  // 번역도구
  const { t, i18n } = useTranslation('common');
  // 데이터
  const { data: popularItems } = useQuery(GET_SEARCH_KEYWORD_LIST, {
    variables: {
      lang: convertLangCodeForDB(i18n.language),
    },
  });

  return (
    <SearchList onMouseDown={(e) => e.preventDefault()}>
      {recentItems.length !== 0 && (
        <>
          <RemoveAllItemButton onClick={onClickRemoveAllItemButton}>
            <Image src="/icons/trash-can-icon.svg" width="14" height="14" alt="" />
            {t('menu.gnb.search.removeAll')}
          </RemoveAllItemButton>
          <Group title={t('menu.gnb.search.recent')} items={recentItems} onBlurInput={onBlurInput} />
        </>
      )}
      <Group
        isPopulKeyword={true}
        title={t('menu.gnb.search.popular')}
        items={popularItems && popularItems.searchKeywordList && popularItems.searchKeywordList.map((x) => x.keyword)}
        onBlurInput={onBlurInput}
      />
      <SearchFooter>
        <Link href={isMobile ? '/category' : '#category'} prefetch={false}>
          <SearchFooterLink
            className="search__category"
            onClick={() => {
              onBlurInput();
            }}
          >
            <FlexRowAlignCenter>
              <CustomText size={12} color={'#f300ba'}>
                {t('menu.gnb.search.findByCategory')}
              </CustomText>
              <RightArrowIcon size={12} color={'#f300ba'} />
            </FlexRowAlignCenter>
          </SearchFooterLink>
        </Link>
      </SearchFooter>
    </SearchList>
  );
};
