import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { CustomLink } from '@components/ui';
import * as Styled from './styled';

type Coupon = {
  code: string;
  name: string;
  isLink: boolean;
};

type Props = {
  item: Coupon;
  activeId: string;
  isMobile: boolean;
  onMouseAction: any;
  onClick: any;
};

export const MainContainer: React.FC<Props> = ({ item, activeId, isMobile, onMouseAction, onClick }) => {
  const isActive = activeId === item.code;
  const isActiveLink = !isMobile && item.isLink;
  const itemRef = useRef<HTMLDivElement>(null);
  const [isTwoLine, setIsTwoLine] = useState(false);

  useEffect(() => {
    if (itemRef) {
      const itemHeight = itemRef.current?.clientHeight;
      setIsTwoLine(itemHeight > 32);
    }
  }, [itemRef]);

  // 카테고리 명을 가져오는 메소드입니다.
  const getCategoryName = (name) => {
    // 카테고리 현재 규칙의 경우, #{imageName}#{categoryName} 으로 되어있어 이미지 태그로 치환해주어야 합니다.
    // 카테고리명 정규식 (# 으로 묶여진 항목은 이미지 파일명)
    const cateRegex = /#(.*?)#/g;
    // 이미지 명
    let imgName = name.match(cateRegex);
    // 이미지명 확보
    imgName = imgName ? imgName[0].replace(/#/g, '') : imgName;
    imgName = imgName ? '/icons/category/' + imgName + '.png' : imgName;
    // 카테고리 명 변환
    return imgName ? (
      <>
        <Image alt="" src={imgName} width="16" height="16" />
        <Styled.MainCategoryTitle className={'ml-2'} isTwoLine={isTwoLine}>
          {name.replace(cateRegex, '')}
        </Styled.MainCategoryTitle>
      </>
    ) : (
      <Styled.MainCategoryTitle isTwoLine={isTwoLine}>{name}</Styled.MainCategoryTitle>
    );
  };

  return (
    <Styled.MainCategoryGroupItem
      isActive={isActive}
      isActiveUnderline={isActive && item.isLink}
      onMouseOver={isMobile ? () => undefined : onMouseAction.active(item.code, true)}
      onMouseLeave={isMobile ? () => undefined : onMouseAction.inActive()}
      onClick={!isMobile ? () => undefined : onMouseAction.active(item.code, false)}
      ref={itemRef}
    >
      {isActiveLink ? (
        <CustomLink className={'w-full'} href={!isActiveLink ? '#category' : `/category/${item.code}`}>
          <div className={'flex items-center w-full'} onClick={onClick}>
            {getCategoryName(item.name)}
          </div>
        </CustomLink>
      ) : (
        getCategoryName(item.name)
      )}
    </Styled.MainCategoryGroupItem>
  );
};
