import { IProductClickEvent, IShareViewEvent } from './type';
import mixpanel from '.';

export const productClickEventLog = (property: IProductClickEvent) => {
  if (!property.pageName) return;

  mixpanel.event('Product Click', {
    'Page Name': property.pageName,
    'Action Name': property.actionName,
    'Product Name': property.productName,
    'Product No': property.productNo,
    ...(property?.groupTitle ? { 'Group Title': property?.groupTitle } : {}),
    ...(property?.groupSno ? { 'Group Sno': property?.groupSno } : {}),
    ...(property?.keyword ? { Keyword: property?.keyword } : {}),
    Description: `order_${property.order}`,
  });

  return true;
};

export const shareViewEventLog = (property: IShareViewEvent) => {
  if (!property.url) return;
  mixpanel.event('Share View', property);
};
