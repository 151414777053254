import React from 'react';
import { useTranslation } from 'next-i18next';
import { CustomImage, CustomLink, CustomText } from '@components/ui';
import { colors } from '@public/theme';
import { ProductCardsWithBrandQuery } from 'src/generated/graphql';
import PriceText from '@components/PriceText';
import { EndSalesText } from '../Card/styled';
import * as Styled from './styled';

type SmallCardType = ProductCardsWithBrandQuery['productCards'][0] & {
  onAddCart: () => void;
};

/**
 * 현재 마이페이지-유저 홈 에서 사용하고 있으며,
 * 기존 카드보다 사이즈가 작습니다.
 */
export const SmallCard: React.FC<SmallCardType> = ({
  id,
  mainImageUrl,
  subImageUrl,
  brand,
  title,
  price,
  onDisplay = true,
  onSale = true,
  onAddCart,
}) => {
  const { t } = useTranslation('common');
  // 상품 상세 URL
  const goodsViewURL = `/product/${id}`;

  return (
    <Styled.Container>
      {/* 상품 이미지 영역 */}
      <Styled.Image>
        <div className={!onDisplay ? 'opacity-40 pointer-events-none' : ''}>
          <CustomLink href={goodsViewURL} newWindow>
            <Styled.MainImage>
              <CustomImage src={subImageUrl} width={180} height={112} alt={title} objectFit={'cover'} />
            </Styled.MainImage>
            <Styled.SubImage>
              <CustomImage src={mainImageUrl} width={180} height={112} alt={title} objectFit={'cover'} />
            </Styled.SubImage>
          </CustomLink>
        </div>
        {!onDisplay && (
          <Styled.EndSalesSmall>
            <EndSalesText>{t('product.endSales')}</EndSalesText>
          </Styled.EndSalesSmall>
        )}
      </Styled.Image>

      {/* 상품 제목 및 브랜드 명 영역 */}
      <Styled.Contents isDisabled={!onDisplay}>
        <CustomLink href={goodsViewURL} newWindow>
          <Styled.TitleText>{title}</Styled.TitleText>
        </CustomLink>
        <CustomLink href={`/brand/${brand?.code}`} newWindow>
          <CustomText className={'mt-2'} size={11} color={colors.gray.c5}>
            {brand?.name}
          </CustomText>
        </CustomLink>
      </Styled.Contents>

      {/* 가격 및 장바구니 영역*/}
      <div className={'flex justify-between items-end h-7'}>
        <CustomText size={12} className={!onDisplay ? 'opacity-30' : ''}>
          <PriceText space={0.5} amount={price} type={'display'} commonProps={{ size: 12 }} />
        </CustomText>
        {onDisplay && (
          <Styled.ScrapCart
            onSale={onSale}
            isScrapEdit={false}
            goodsNo={id.toString()}
            onAddCart={onAddCart}
            legacy={true}
          />
        )}
      </div>
    </Styled.Container>
  );
};
