import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import jwt from 'jsonwebtoken';
import { useInterval } from 'react-use';
import axios from 'axios';
import { GET_CLIPS, GET_NOTIFICATION } from '@api';
import { deserializeAuthToken, serializeAuthToken } from '@lib/auth';
import { useCartQuery } from 'src/generated/graphql';
import * as mixpanel from '@lib/mixpanel';
import { useCartOnSession } from '@hooks/cartOnSession';

type Clips = {
  id: number;
  godoGoodsNo: number;
};

type Carts = {
  godoGoodsNo: number;
};

export type Notifications = {
  type: string;
  message: string;
};

export type UserInfo = {
  memNo: string;
  userId: string;
};
type AccessTokenPayload = {
  username: string;
  sub: string;
  iat: number;
  exp: number;
};

export const UserInfoStatusEnum = {
  loading: 'loading',
  unauthenticated: 'unauthenticated',
  authenticated: 'authenticated',
} as const;
export type UserInfoStatus = (typeof UserInfoStatusEnum)[keyof typeof UserInfoStatusEnum];

// throttle refresh request. at most once a second.
let refreshLock = false;

export const CommonContext = createContext(null);
export const CommonProvider = ({ children, tokenStorageHook }) => {
  const [clips, setClips] = useState<Clips[]>([]);
  const [carts, setCarts] = useState<Carts[]>([]);
  const [cartsOnSession, setCartsOnSession] = useCartOnSession();
  const [notification, setNotification] = useState<Notifications[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [userInfoStatus, setUserInfoStatus] = useState<UserInfoStatus>(UserInfoStatusEnum.loading);

  const { authToken, setAuthToken, removeAuthToken } = tokenStorageHook;

  const updateUserInfoFromToken = () => {
    const { accessToken, refreshToken } = deserializeAuthToken(authToken);

    const payload = jwt.decode(accessToken) as AccessTokenPayload;

    if (payload?.username) {
      // 2시간을 기준으로 갱신
      if (new Date(payload.exp * 1000) < new Date(new Date().getTime() + 1000)) {
        // access token expired
        if (!refreshLock) {
          // disable multiple refresh request.
          refreshLock = true;
          setTimeout(() => {
            refreshLock = false;
          }, 3000);

          setUserInfoStatus(UserInfoStatusEnum.loading);
          axios
            .post(process.env.NEXT_PUBLIC_AUTH_V2_URL + '/acon/refresh', JSON.stringify({ access_token: accessToken, refresh_token: refreshToken }), {
              withCredentials: true,
              headers: { 'Content-Type': 'application/json' },
            })
            .then(function (response) {
              // 토큰 갱신 성공
              setAuthToken(serializeAuthToken(response.data.access_token, response.data.refresh_token));
              setUserInfoStatus(UserInfoStatusEnum.authenticated); // 사실 없어도 동작하긴 함
              refreshLock = false;
            })
            .catch(function () {
              removeAuthToken();
              setUserInfoStatus(UserInfoStatusEnum.unauthenticated);
              refreshLock = false;
            });
        }
      } else {
        if (userInfo?.memNo !== payload.sub) {
          const userInfoFromPayload = {
            userId: payload.username,
            memNo: payload.sub,
          };
          setUserInfo(userInfoFromPayload);
        }

        setUserInfoStatus(UserInfoStatusEnum.authenticated);
      }
    } else {
      // 비회원 (로그인 하지 않은) 상태
      setUserInfoStatus(UserInfoStatusEnum.unauthenticated);
    }
  };

  useEffect(() => {
    updateUserInfoFromToken();
  }, [authToken]);

  // 30분마다 주기적으로 인증 상태를 확인해서, 만료되었으면 토큰을 갱신
  useInterval(() => {
    if (authToken) updateUserInfoFromToken();
  }, 30 * 60 * 1000);

  // 스크랩 정보 가져오기
  const {
    data: clipsData,
    loading: clipsLoading,
    refetch: clipsRefetch,
  } = useQuery(GET_CLIPS, {
    skip: userInfoStatus !== UserInfoStatusEnum.authenticated,
    ssr: false,
  });

  // 장바구니 정보 가져오기
  const {
    data: cartsData,
    loading: cartsLoading,
    refetch: cartsRefetch,
  } = useCartQuery({
    skip: userInfoStatus !== UserInfoStatusEnum.authenticated,
    ssr: false,
  });

  // 알림 정보 가져오기
  const {
    data: notificationData,
    loading: notiLoading,
    refetch: notiRefetch,
  } = useQuery(GET_NOTIFICATION, {
    skip: userInfoStatus !== UserInfoStatusEnum.authenticated,
    ssr: false,
  });

  // 로그인한 회원이 바뀌면 장바구니랑 스크랩 정보 갱신
  useEffect(() => {
    if (userInfo) {
      clipsRefetch();
      cartsRefetch();
      notiRefetch();
    }
  }, [userInfo]);

  // 스크랩 정보 세팅
  useEffect(() => {
    if (!clipsLoading && clipsData) {
      setClips((clipsData?.clips || []).map((clips: Clips) => ({ id: clips.id, godoGoodsNo: Number(clips.godoGoodsNo) })));
    }

  }, [clipsLoading, clipsData]);

  // 장바구니 개수 정보 세팅
  useEffect(() => {
    if (!cartsLoading && cartsData) {
      setCarts((c) =>
        Array.from(new Set([...c.map((x) => x.godoGoodsNo), ...(cartsData?.cart || []).map((carts) => Number(carts.productNo)), ...(cartsOnSession || [])])).map((godoGoodsNo) => ({
          godoGoodsNo,
        })),
      );
    }
  }, [cartsLoading, cartsData]);

  // 알림 정보 세팅
  useEffect(() => {
    if (!notiLoading && notificationData) {
      setNotification(
        (notificationData?.aconNotifications || []).map((noti: Notifications) => ({
          type: noti.type,
          message: noti.message,
        })),
      );
    }
  }, [notiLoading, notificationData]);

  // 스크랩 추가 함수
  const handleAddClip = (id: number, godoGoodsNo: number) => {
    const isOverlap = clips.some((x) => x.godoGoodsNo === godoGoodsNo);
    if (!isOverlap) setClips([...clips, { id: id, godoGoodsNo: godoGoodsNo }]);
  };

  // 스크랩 제거 함수
  const handleRemoveClip = (godoGoodsNo: number | number[]) => {
    const goodsNos = typeof godoGoodsNo === 'object' ? godoGoodsNo : [godoGoodsNo];
    if (!goodsNos || goodsNos.length === 0) return;
    setClips([...clips.filter((x) => !goodsNos.includes(x.godoGoodsNo))]);
  };

  // 장바구니 추가 함수
  const handleAddCart = (godoGoodsNo: number | number[]) => {
    const goodsNos = typeof godoGoodsNo === 'object' ? godoGoodsNo : [godoGoodsNo];
    if (goodsNos && goodsNos.length > 0) {
      const cartsGoodsNos = carts.map((x) => x.godoGoodsNo);
      setCarts((carts_arg) => [
        ...carts_arg,
        ...goodsNos
          .filter((goodsNo) => !cartsGoodsNos.includes(goodsNo))
          .map((goodsNo) => {
            return { godoGoodsNo: goodsNo };
          }),
      ]);

      mixpanel.event('Add Cart', {
        goodsNos: goodsNos,
        status: userInfoStatus,
      });
    }
  };

  // 장바구니 제거 함수
  const handleRemoveCart = (godoGoodsNos: number[]) => {
    if (!godoGoodsNos || godoGoodsNos.length === 0) return;
    setCarts([...carts.filter((x) => !godoGoodsNos.includes(x.godoGoodsNo))]);
  };

  // 알림 정보 추가 함수
  const handleAddNotification = (type: string, message?: string) => {
    const isOverlap = notification.some((x) => x.type === type);
    if (!isOverlap) setNotification([...notification, { type: type, message: message }]);
  };

  // 알림 정보 제거 함수
  const handleRemoveNotification = (types: string[]) => {
    if (!types && types.length === 0) return;
    setNotification([...notification.filter((x) => !types.includes(x.type))]);
  };

  return (
    <CommonContext.Provider
      value={{
        userInfo: userInfo,
        userInfoStatus: userInfoStatus,
        removeAuthToken: removeAuthToken,
        setAuthToken: setAuthToken,
        clips: clips,
        onAddClip: handleAddClip,
        onRemoveClip: handleRemoveClip,
        carts: carts,
        setCarts,
        onAddCart: handleAddCart,
        onRemoveCart: handleRemoveCart,
        cartsOnSession,
        setCartsOnSession,
        notification: notification,
        onAddNotification: handleAddNotification,
        onRemoveNotification: handleRemoveNotification,
        notiLoading: notiLoading,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};
