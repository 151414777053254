import React from 'react';
import Link from 'next/link';
import { unescape } from 'lodash';
import useSettings from '@hooks/useSettings';
import { GroupContents, GroupItem, GroupItemLink, GroupItemRank, GroupTitle, GroupWrapper } from './styled';

interface GroupProps {
  title: string;
  items: [string?];
  isPopulKeyword?: boolean;
  onBlurInput: () => void;
}

export const Group = ({ title, items, isPopulKeyword, onBlurInput }: GroupProps) => {
  const { themeMode } = useSettings();

  const handleSearchItemClick = () => {
    onBlurInput();
  };

  return (
    <GroupWrapper>
      <GroupTitle isPopul={isPopulKeyword}>{title}</GroupTitle>
      <GroupContents>
        {items &&
          items.map((keyword, i) => (
            <GroupItem
              key={i}
              onClick={() => handleSearchItemClick()}
              data-mixpanel-action="click"
              data-mixpanel-evt="Trigger Search"
              data-mixpanel-keyword={keyword}
              data-mixpanel-method={isPopulKeyword ? 'popular' : 'recent'}
            >
              <Link href={`/${themeMode}/search?keyword=${keyword}`} prefetch={false}>
                <GroupItemLink>
                  {/* 인기 검색어 순위 3위까지 분홍색 그 이하는 검은색으로 설정 */}
                  {isPopulKeyword && <GroupItemRank color={i <= 2 ? '#f300ba' : '#333'}>{i + 1}.</GroupItemRank>}
                  {unescape(keyword)}
                </GroupItemLink>
              </Link>
            </GroupItem>
          ))}
      </GroupContents>
    </GroupWrapper>
  );
};
