import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { ADD_WISH_GOODS, DELETE_WISH_GOODS } from '@api';
import { CommonContext } from '../provider';
import { UserInfoStatusEnum } from '../provider';
import { useUserInfo } from './userInfo';

// 스크랩(clip)에 대한 custom hook 입니다.
export const useClip = (goodsNo: number) => {
  const [isClip, setIsClip] = useState(false);
  const { clips, onAddClip, onRemoveClip } = useContext(CommonContext);
  const { goLoginIfNecessary } = useUserInfo();

  useEffect(() => {
    if (clips) setIsClip(clips.some((clip) => clip.godoGoodsNo === goodsNo));
  }, [clips]);

  // 스크랩하기 액션
  const [addClipGoods] = useMutation(ADD_WISH_GOODS);
  // 스크랩 제거하기 액션
  const [deleteClipGoods] = useMutation(DELETE_WISH_GOODS);

  const addClip = () => {
    // sno 번호가 존재하지 않을경우 (스크랩 항목이 존재하지 않을경우)
    // 스크랩을 추가한다.
    return addClipGoods({
      variables: {
        goodsNo: goodsNo,
      },
    }).then((result) => {
      const clipId = result.data.createClip?.id || null;
      if (clipId) onAddClip(clipId, goodsNo);
    });
  };

  const removeClip = () => {
    // 스크랩을 제거한다.
    return deleteClipGoods({
      variables: {
        godoGoodsNo: goodsNo,
      },
    }).then(() => {
      onRemoveClip(goodsNo);
    });
  };

  const handleClip = () => {
    // 사용자 정보가 존재하지 않을경우
    if (goLoginIfNecessary() !== UserInfoStatusEnum.authenticated) return;

    // 사용자 정보가 존재함
    // sno 번호가 존재할 경우(스크랩 항목이 존재할 경우)
    if (isClip) {
      // 스크랩을 제거한다.
      removeClip();
    } else {
      // 스크랩을 추가한다.
      addClip();
    }
  };

  return { isClip, handleClip };
};
