import React, { CSSProperties, FC, ReactEventHandler } from 'react';
import Image, { ImageLoader, ImageProps } from 'next/image';
import { isIE } from 'react-device-detect';

type CustomImageProps = {
  width: number;
  height: CSSProperties['height'];
  backgroundColor?: string;
  src: string;
  objectFit: 'fill' | 'cover' | 'contain' | 'scale-down';
  alt: string;
  onLoad?: ReactEventHandler<HTMLImageElement>;
  onClick?: ReactEventHandler<HTMLImageElement>;
  priority?: boolean;
  quality?: number;
  placeholder?: 'blur' | 'empty';
  className?: string;
  isUnoptimized?: boolean;
  layout?: ImageProps['layout'];
};

const normalizeSrc = (src) => {
  return src[0] === '/' ? src.slice(1) : src;
};

const cloudflareLoader: ImageLoader = ({ src, width, quality }) => {
  if (src.endsWith('.gif')) {
    //  gif 확장자의 경우 최적화 하지 않음
    return src;
  }

  return `https://www.acon3d.com/cdn-cgi/image/f=webp,w=${width},q=${quality || 85}/${normalizeSrc(src)}`;
};

const CustomImage: FC<CustomImageProps> = (props) => {
  const { width, height, backgroundColor, objectFit, className, isUnoptimized = false, ...rest } = props;

  // /imgs/...
  // 해당 프로젝트에 포함해서 static file 로 서빙하는 이미지들의 경우에는,
  // 로컬 & 스테이징 환경 등에서 아래의 경로로 접근이 안되므로 변환하지 않는다.
  // 장기적으로는 파일들을 모두 azure storage에 올리는 것이 바람직 할 것.
  //
  // gif
  // 버그인지 의도된 것인지, cloudflare 에서 gif가 avif 형식으로 변환되면
  // anim=false 옵션이 없더라도 정적인 이미지로 변환이 되어버려서, gif는 예외처리
  // 장기적으로는 불필요한 gif 파일을 없애고, video 형식을 사용하는게 좋을 듯.
  const unoptimized = props.src.startsWith('/imgs') || props.src.endsWith('gif');

  const style: any = {
    width: width,
    height: height,
    position: 'relative',
  };

  if (backgroundColor) style.backgroundColor = backgroundColor;

  return (
    <div style={style} className="custom-img">
      <Image
        className={className}
        layout={rest.layout ? rest.layout : 'fill'}
        {...(rest.layout ? { width, height } : {})}
        sizes={width + 'px'}
        {...(!isIE && {
          objectFit: objectFit,
        })}
        unoptimized={isUnoptimized || unoptimized}
        loader={cloudflareLoader}
        lazyBoundary={'600px'}
        placeholder={'blur'}
        blurDataURL={
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8vAkAAl4Bh+J6dBIAAAAASUVORK5CYII='
        }
        alt={encodeURIComponent(props.src)}
        {...rest}
      />
    </div>
  );
};

export { CustomImage };
