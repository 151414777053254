import { useContext, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { CommonContext, UserInfo, UserInfoStatus, UserInfoStatusEnum } from '../provider';

type UseUserInfoOptions = {
  authRequired: boolean;
};

export const useUserInfo = (options?: UseUserInfoOptions) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  let userInfoWrapper: { userInfo: UserInfo; userInfoStatus: UserInfoStatus } =
    useContext(CommonContext);

  if (!userInfoWrapper?.userInfo) {
    Object.assign(userInfoWrapper, {
      userInfo: { memNo: '', userId: '' },
      status: UserInfoStatusEnum.unauthenticated });
  }

  const { userInfo, userInfoStatus: status } = userInfoWrapper;

  const isAuthenticated = status === UserInfoStatusEnum.authenticated;

  // 로그인 검증 후, 로그인 페이지로 이동하기 메소드
  const goLoginIfNecessary = (message?: string) => {
    if (status === UserInfoStatusEnum.unauthenticated) {
      window.alert(message || t('message.pleaseLogin'));
      router.push({
        pathname: '/users/login',
        query: { returnUrl: router.asPath },
      });
    } else if (status === UserInfoStatusEnum.loading) {
      window.alert(t('message.pleaseWait'));
    }
    return status;
  };

  const { authRequired } = options ?? {};
  const requiredAndNotLoading = authRequired && status === UserInfoStatusEnum.unauthenticated;

  useEffect(() => {
    if (requiredAndNotLoading) goLoginIfNecessary();
  }, [requiredAndNotLoading]);
  if (requiredAndNotLoading) return { userInfo, status: UserInfoStatusEnum.loading, isAuthenticated, goLoginIfNecessary } as const;

  return { userInfo, status, isAuthenticated, goLoginIfNecessary };
};
