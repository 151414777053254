import { useMemo } from 'react';
import { ApolloClient, createHttpLink, from, InMemoryCache, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { print } from 'graphql';
import { QueryOptions } from '@apollo/client/core/watchQueryOptions';
import { requestRefresh } from '@lib/auth';

let apolloClient;

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
  credentials: 'include',
});

export const catchableQueryFactory = (apolloClient: ApolloClient<any>, isAllowedError: boolean = false) => <TQuery>(query: QueryOptions<TQuery>, isAllowedErrorSpec: boolean = false) => {
  const queryDoc = print(query.query);
  return apolloClient.query(query).catch(error => {
    if (!error.name) {
      try {
        error.name = queryDoc.split('\n')[1].split('(')[0].trim();
      } catch (e) { /*handled*/
      }
    }
    error.extra = { query: queryDoc, variables: query.variables };
    if (isAllowedError || isAllowedErrorSpec) return error;
    throw error;
  });
};

const createApolloClient = (link) => {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: link,
    cache: new InMemoryCache(),
  });
};

export function useApollo(initialState, tokenStorageHook) {
  // 401(auth) error handling. more info https://www.apollographql.com/docs/react/data/error-handling/#advanced-error-handling-with-apollo-link
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions.code) {
        // when an AuthenticationError is thrown in a resolver
          case 'UNAUTHENTICATED':
            return new Observable((observer) => {
              requestRefresh(tokenStorageHook)
                .then(() => {
                // then access_token(cookie) is fresh

                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
                  // Retry last failed request
                  forward(operation).subscribe(subscriber);
                })
                .catch((error) => {
                  observer.error(error);
                });
            });
        }
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  return useMemo(() => {
    return initializeApollo(initialState, errorLink);
  }, [initialState]);
}

export const initializeApollo = (initialState = null, errorLink = null): ApolloClient<any> => {
  const _apolloClient =
    apolloClient ?? (errorLink ? createApolloClient(from([errorLink, httpLink])) : createApolloClient(httpLink));

  if (initialState) {
    const existingCache = _apolloClient.extract();

    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};


